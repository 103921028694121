/* eslint "sort-keys": ["warn", "asc", {"caseSensitive": false, "natural": false, "minKeys": 2}] */
export default {
  ActivityLevelPopover: {
    editButtonLabel: 'Edit',
    permanentlyDeleteButtonLabel: 'Permanently Delete',
    renameButtonLabel: 'Rename',
  },
  ActivityLevels: {
    activityLevelTabTitle: 'Activity Levels',
    addProfileLabel: 'Add Profile',
    createActivityButtonLabel: 'Create',
    createActivityLevelButtonTitle: 'Create Activity Level',
    createActivityLevelModalHeader: 'Create an Activity Level',
    createActivityLevelModalSubHeader:
      'Choose a name for this Activity Level. You can change this name later.',
    errorMessagept1: 'There are no profiles for group ',
    errorMessagept2:
      '. Add profiles to this group to begin creating and managing activity levels.',
    groupPageDescription:
      'Manage profiles and activity levels for this group to customize configurations',
    myProfilesTabTitle: 'Profiles',
    nameDuplicateError:
      'This name is already being used for another Activity Level',
    nameLabel: 'Name',
    namePlaceHolder: 'Enter a name',
  },
  ActivityLevelSelectionModal: {
    activityLevel: 'Activity Level:',
    cancel: 'Cancel',
    changeButton: 'Change',
    description:
      "To edit {{profileFeature}}, choose an activity level that you'd like to modify for this profile",
    dropdownTitle: 'Activity Level',
    placeholderText: 'Select activity level',
    select: 'Select',
    title: 'Select an Activity Level',
  },

  AddProfile: {
    AddProfileAddButtonLabel: 'Add',
    AddProfileHeader: 'Add Profiles to ',
    AddProfileSubHeader: 'You can only add profiles that are not in a Group',
  },

  AlertTimers: {
    alertTimerLabel: 'Alert Time',
    carryOutLabel: 'Carry-out Transaction Alerts',
    carryOutTitle: 'Carry-out',

    cateringDeliveryLabel: 'Catering Delivery Transaction Alerts',
    cateringDeliveryTitle: 'Catering Delivery',
    cateringPickupLabel: 'Catering Pickup Transaction Alerts',
    cateringPickupTitle: 'Catering Pickup',
    cautionTimerLabel: 'Caution Time',
    cfaDeliveryLabel: 'CFA Delivery Transaction Alerts',
    cfaDeliveryTitle: 'CFA Delivery',

    curbsideLabel: 'Curbside Transaction Alerts',
    curbsideTitle: 'Curbside',
    dineInLabel: 'Dine-in Transaction Alerts',
    dineInTitle: 'Dine-In',
    driveThruLabel: 'Drive-thru Transaction Alerts',
    driveThruTitle: 'Drive-thru',
    homePageDescription: '',
    homePageTitle: 'Alert Timers',
    mCarryOutLabel: 'Mobile Carry-out Transaction Alerts',
    mCarryOutTitle: 'Mobile Carry-out',
    mDineInLabel: 'Mobile Dine-in Transaction Alerts',
    mDineInTitle: 'Mobile Dine-in',
    mDriveThruLabel: 'Mobile Drive-thru Transaction Alerts',
    mDriveThruTitle: 'Mobile Drive-thru',
    minutesSecondLabel: 'In minutes and seconds',
    thirdPartyDeliveryLabel: 'Third Party Delivery Transaction Alerts',
    thirdPartyDeliveryTitle: 'Third Party Delivery',
  },

  AllTransactions: {
    customerDescriptors: 'Customer descriptors',
    customerFulfillment: 'Customer fulfillment method',
    customerName: 'Customer name',
    customerPhoneNumber: 'Customer phone number',
    customerTitle: 'Customer',
    deliveryZone: 'Delivery zone',
    fontSize: 'Font size for menu items',
    fulfillmentTitle: 'Fulfillment',
    homePageDescription: 'Prints all items on the order',
    homePageTitle: 'All Transaction Receipt',
    largeButton: 'Large',
    menuItemsTitle: 'Menu Items',
    orderNumber: 'Order number',
    orderNumberValue: 'Prints by default',
    orderTakingTitle: 'Order-Taking',
    registerNumber: 'Register number',
    smallButton: 'Small',
    teamMemberName: 'Team member name',
  },
  AppRoutes: {
    baseName: 'ViewPoint Home',
    createProfile: 'Create profile',
    mainMenu: 'Main Menu',
  },
  Bump: {
    allowBumpDesc:
      'The order states selected determine which order states can be bumped',
    allowBumpTitle: 'Allow bump at order states',
    bumpsHeaderBeginning: 'Choose ',
    bumpsHeaderBold: 'at least one ',
    bumpsHeaderEnd:
      'way to remove orders on VP screens that will have this profile enabled.',
    choice1: 'Remove order when bumped by',
    choice2: 'Remove order when it is ',
    enableLeadBumpDescPT1: 'When turned on, bumps from the current profile, ',
    enableLeadBumpDescPT2:
      ', will also bump orders from the profiles selected below (select at least one profile):',
    enableLeadBumpPopup:
      'This is the equivalent of master bump for KPS Classic devices.',
    enableLeadBumpTitle: 'Enable lead bump',
    finalBumpDescPT1:
      'When the final bump is selected, bumps from the current profile, ',
    finalBumpDescPT2:
      ', mark the speed of service time and send mobile customers an order-ready notification. If your restaurant has ViewPoint and KPS Classic, this will also mark orders as complete and master bump orders off of SP Classic KPS.',
    finalBumpTitle:
      'Final bump for Speed of Service (SoS) and customer notification',
    updateItemsDesc:
      'Select the status that an item is marked when it is bumped on LBM or CFA kitchen screens',
    updateItemsTitle: 'Update items on order',
    viewMoreProfilesButton: 'View more profiles',
  },

  CopyProfile: {
    copyConfirmLabel: 'Create Profile',
    copyLabel: 'Name',
    copyPlaceholder: 'Enter a name',
    // copyProfileLabel: 'Copy',
    descriptionBeginning: 'Are you sure you want to copy the VP profile, ',
    descriptionEnd:
      '? When a VP Profile is copied, its settings are also copied.',
    //duplicateLabel: 'Duplicate',
    duplicateProfile: 'Copy Profile',
  },
  CopyProfileInGroup: {
    copyConfirmLabel: 'Copy profile',
    copyLabel: 'Name',
    copyPlaceholder: 'Enter a name',
    // copyProfileLabel: 'Copy',
    descriptionBeginning: 'Are you sure you want to copy the VP profile, ',
    descriptionEnd:
      '? When a VP Profile is copied, its settings are also copied.',
    //duplicateLabel: 'Duplicate',
    duplicateProfile: 'Copy Profile',
    nameDuplicateError: 'This name is already being used for another Profile',
  },
  CreateGroup: {
    colon: ':',
    createButtonLabel: 'Create',
    header: 'Create a Group',
    label: 'Name',
    nameDuplicateError: 'This name is already being used for another Group',
    nextButtonLabel: 'Next',
    step1Directions:
      'Choose a name for this group. By creating this group, you will be able to set up activity levels that apply to all profiles in this group.',
    step1Label: 'Step 1 of 2',
    step2Directions: `If you'd like, select profiles for this group.`,
    step2Label: 'Step 2 of 2 (Optional):',
  },
  CreateProfile: {
    bump: 'Bump Component',
    continue: 'Continue',
    createGroupLabel: 'Create group',
    createGroupTooltip:
      'By creating a group, you can set up activity level that apply to all profiles in a group',
    createProfileLabel: 'Create profile',
    destinations: 'Destinations Component',
    importKPSSettings: 'Import KPS Settings',
    layout: 'Layout Component',
    menuItems: 'Menu Items Compoent',
    nameDuplicateError: 'This name is already being used for another Profile',
    review: 'Review Compoent',
    role: 'Role Component',
    stepperSubtitle: 'Complete the following steps to create a new profile',
    successMessageCopyProfile: 'has been created',
    successMessageRenameProfile: 'has been renamed',
  },
  DeleteActivityLevel: {
    cancelButtonTitle: 'Cancel',
    deleteButtonTitle: 'Delete activity level',
    header: 'Delete Activity Level ',
    subtitle: 'Are you sure you want to delete the activity level, ',
    warningMessage:
      'When you delete an activity level, all profile configurations for this activity level will also be deleted.',
  },
  DeleteGroupLabels: {
    cancelButtonLabel: 'Cancel',
    deleteButtonLabel: 'Delete group',
    errorMessageBlockContent:
      'The group you tried to delete has profiles in it. To successfully delete this group, remove these profiles from the group or delete them.',
    errorMessageBlockTitle: 'Unable to delete ',
    header: 'Delete Group',
    subHeader: 'Are you sure you want to delete this group',
  },
  DeleteProfileLabels: {
    cancelButtonLabel: 'Cancel',
    deleteButtonGroupLabel: 'Delete profile',
    deleteButtonLabel: 'Delete profile',
    header: 'Delete Profile',
    subheaderDeleteGroupProfile:
      'Are you sure you want to delete the profile, ',
    subtitle: 'Are you sure you want to delete the profile',

    warningMessage:
      'When you delete a profile in a group, configurations for all activity levels will also be deleted.',
  },
  DestinationLabel: {
    fulfillmentHeaderBeginning: 'Choose ',
    fulfillmentHeaderBold: 'at least one order fulfillment ',
    fulfillmentHeaderEnd: 'option to route to this profile',
    originHeaderBeginning: 'Choose ',
    originHeaderBold: 'at least one origin ',
    originHeaderEnd: 'to route to this profile',
  },
  EditActivityLevel: {
    header: 'Rename Activity Level',
  },
  EditProfile: {
    Bumps: 'Bumps',
    itemRouting: 'Item Routing',
    Label: 'Edit Profile',
    Menu: 'Menu Items',
    OrderCardDetails: 'Order Card Details',
    OrderDestinations: 'Order Destinations',
    OrderFulfil: 'Order Origin & Fulfillment',
    OrderStates: 'Order States',
    PermanentlyDeleteButtonLabel: 'Permanently Delete',
    ReceiptPrinting: 'Receipt Printing',
    RenameButtonLabel: 'Rename',
    Role: 'Role',
    Subtitle: 'Edit configurations to customize VP screens for this profile',
  },
  EditRoleModal: {
    cancel: 'Cancel',
    changeRoleButton: 'Change Role',
    editModalDescription:
      'Are you sure you want to change the role for this profile? This will change the menu items that are currently selected for this profile, Drinks - Test.',
    editModalHeader: 'Change Role',
  },
  ErrorMessage: {
    BumpsLeadBumpListener:
      'There must be at least one profile selected when enable lead bump is turned on. To continue, please select at least one profile.',
    BumpsProfilesInfoPopupPT1:
      "This profile's orders are bumped when orders from ",
    BumpsProfilesInfoPopupPT2:
      " are bumped. To change this, navigate to the profile's Bumps section.",
    BumpsProfilesOrStatusesHeader: 'No Bumps Profiles or Statuses',
    BumpsProfilesOrStatusesSubtitle:
      'There are currently no bump profiles or statuses options selected. To continue, you must choose at least one from category.',
    EmptyInputError: 'Name can not start with space',
    EmptyStringError: 'Name cannot be empty',
    ItemRoutingHeader: 'No Items Selected',
    ItemRoutingSubtitle:
      'There are currently no selections in the items. To continue, you must choose at least one.',
    MenuItemsHeader: 'No Menu Items Selected',
    MenuItemsSubtitle:
      'There are currently no selections in the menu items. To continue, you must choose at least one.',
    OrderDestinationsSubtitle:
      'There are currently no order destinations selected. Unless a selection is made, no orders will be routed to this profile.',
    OrderDestinationsTitle: 'No Order Destinations Selected',
    OrderOriginAndFulfillmentSubtitle:
      'There are currently no order origins or fulfillment options selected. To continue, you must choose at least one from category.',
    OrderOriginOrFulfillmentHeader: 'No Origin Order or Fulfillment',
    OrderOriginOrFulfillmentSubtitle:
      'There are currently no selections in either the origin or fulfillment category. To continue, you must choose at least one from category.',
    ReceiptPrintingInfoPopup:
      ' selected to print via this profile. To make adjustments, navigate to the Receipt Printing for that profile.',
    SaveVpProfileEmptyErrorMessage:
      'There was an error saving empty profile. Please try again.',
    SaveVpProfileErrorMessage:
      'There was an error saving this profile. Please try again.',
  },
  GroupNameInput: {
    editGroupHeader: 'Rename Group',
  },
  HistoryPageLabel: {
    copyProfile: 'Copy Profile',
    copyProfileCreated: ' has been copied to create ',
    created: 'has been created',
    createProfileLabel: 'Create Profile',
    deleted: 'has been deleted',
    deleteProfileLabel: 'Delete Profile',
    edited: 'has been edited. ',
    editProfileLabel: 'Edit Profile',
    renamedProfile: ' has been renamed to ',
    renameProfile: 'Rename Profile',
    vpProfileLabel: 'VP Profiles',
  },
  Home: {
    homeTitle: 'ViewPoint',
    Subtitle:
      'Manage profiles and groups with activity levels to customize configurations for VP devices',
    title: 'Profiles',
  },

  ItemLabels: {
    customerDescriptors: 'Customer descriptors',
    customerFulfillment: 'Customer fulfillment method',
    customerName: 'Customer name',
    customerPhoneNumber: 'Customer phone number',
    customerTitle: 'Customer',
    deliveryZone: 'Delivery zone',
    fontSize: 'Font size for menu items',
    fulfillmentTitle: 'Fulfillment',
    homePageDescription:
      'Prints individual item receipts for items denoted as specials',
    homePageTitle: 'Items Label (Specials) Receipt',
    largeButton: 'Large',
    menuItemsTitle: 'Menu Items',
    orderNumber: 'Order number',
    orderNumberValue: 'Prints by default',
    orderTakingTitle: 'Order-Taking',
    printRoutedItems: 'Print receipts for all routed items',
    registerNumber: 'Register number',
    routedItemDescription:
      'When turned on, an item label will print for all items that are routed to this profile',
    smallButton: 'Small',
    teamMemberName: 'Team member name',
  },
  ItemLevelRelease: {
    ItemLevelReleaseOffMessage: 'Item-level release is turned off',
    ItemLevelReleaseOnMessage: 'Item-level release is turned on',

    itemReleaseDiscription:
      'When turned off, all menu items will be released to the kitchen at the same time.',

    mainTitle: 'Item-Level Release',
    modalCancel: 'Cancel',
    modalConfirm: 'Confirm',
    modalDisableDescription:
      'Are you sure you want to turn off item-level release? This will cause menu items to be released to the kitchen at the same time.',
    modalDisableHeader: 'Turn off item-level release',
    modalEnableDescription:
      'Are you sure you want to turn on item-level release? This will cause menu items to be released to the kitchen at different times.',
    modalEnableHeader: 'Turn on item-level release',

    subTitle:
      'Configure when Chick-fil-A and Little Blue Menu items are released to the kitchen from preparation',
    title: 'Item-level release',
  },
  ItemRouting: {
    alwaysShowLabel: 'Always Show',
    confimationModalConfirmButton: 'Confirm',
    confirmationModalBody:
      'This will alter the display for all items in this item group.',
    confirmationModalCancelButton: 'Cancel',
    confirmationModalHeader: 'Change routing for',
    displayOptionLabel: 'Display Options',
    editPageDescription:
      'Customize which Item Groups and menu items display on VP devices for this profile',
    ItemLabel: 'Items',
    mixedLabel: 'Mixed',
    neverShowLabel: 'Never Show',
    noResultFoundLabel: 'No Result Found',
    selectedItemLabel: 'selected',
    whenModifiedLabel: 'When Modified',
  },
  Layout: {
    customLabel: 'Custom View',
    gridLabel: 'Grid View',
    headerLabel:
      'Choose a UI layout to customize the appearance of VP screen(s)',
  },
  MenuCategoryDisplay: {
    combos: 'Combos',
    homePageDescription:
      'Change the order of categories and menu items on ViewPoint devices',
    homePageTitle: 'Menu Category & Item Sorting',
  },
  MenuItems: {
    allItemsModalCloseButton: 'Close',
    allMenuDeviceGroupTitle: 'Assigned Device Group',
    allMenuItemsButtonTitle: 'View all menu items',
    allMenuItemsCopyButtonTitle: 'Copy results to clipboard',
    allMenuItemsModalTitle: 'All Menu Items',
    allMenuItemsSortButtonTitle: 'Items',
    cancelButton: 'Cancel',
    closeButton: 'Close',
    copyResultClipboard: 'Copy results to clipbord',
    editButton: 'Edit',
    editedText: 'Edited',
    editItemButton: 'Edit items',
    importAddButton: 'Import',
    importButton: 'Import RMIDS',
    importModalHeader: 'Menu Item RMIDs',
    importModalWarning:
      'Note: Importing the following list will overwrite any previous selections',
    itemsText: 'items',
    menuItemNameText: 'Edit Menu Items for',
    menuTitle:
      'Current selections are based on the profile’s assigned role. Make customizations to all groups and menu items',
    viewAllButton: 'View all menu items',
  },
  MenuSortingItem: {
    bottomButton: 'Move to Bottom',
    homeDescription:
      'Drag and drop or use the menu to reorder menu items within this category',
    topButton: 'Move to Top',
  },
  MobileStepper: {
    bumps: '5. Bumps',
    itemRouting: '3. Item Routing',
    menuitems: '3. Menu Items',
    orderDestination: '2. Order Destination',
    orderState: '4. Order States',
    originAndFulfillment: '2. Origin And Fulfillment',
    receiptPrinting: '6. Receipt Printing',
    review: '7. Review',
    role: '1. Role',
  },
  MoveProfileToGroup: {
    activityLevelGroup: 'Group',
    cancelButtonLabel: 'Cancel',
    modalHeader: 'Move',
    moveButtonLabel: 'Move',
    placeholderLabel: 'Group',
    subHeader: 'To move this profile, choose a Group',
  },
  OrderCardDetails: {
    adminOnly: 'Admin-only',
    alertTimerDecreaseSubtitle:
      'This override will reduce the alert timers configuration across all destinations for this profile.',
    alertTimerDecreaseTitle: 'Enable Alert Timer Override',
    alertTimerMessagept1:
      'These changes will apply to all destinations routed to this profile and reduce all caution and alert timers by ',
    alertTimerMessagept2: ' seconds.',
    alertTimerOverrideSubtitle: 'In minutes and seconds',
    alertTimerOverrideTitle: 'Alert Timer Decrease',
    displayOptionDoNotSubitle:
      'Best for back of house and drinks/frozen treats profiles',
    displayOptionDoNotTitle: 'Do not nest meals',
    displayOptionNestAllSubitle: 'Not recommended in most cases',
    displayOptionNestAllTitle: 'Nest all meals',
    displayOptionNestKidsSubitle: 'Best for bagging and window/serve profiles',
    displayOptionNestKidsTitle: `Nest kid’s meals only`,
    displayOptionSubtitle:
      'When meals are nested, items appear combined within meals and are not broken out into individual items.',
    displayOptionTitle: 'Choose how meals are displayed',
    employeeNameSubtitle:
      'When turned on, the name of the cashier taking the order will show on the order card.',
    employeeNameTitle: 'Show Employee Name',
    employeeNameTurnedOff: 'Show Employee Name is turned off',
    employeeNameTurnedOn: 'Show Employee Name is turned on',
    hoursError: '0-60',
    hoursHelperText: '0-60 minutes',
    pageSubtitle:
      'Customize the appearance of order card details on VP devices',
    pageTitle: 'Order Card Details',
    posSwitchSubtitle:
      'When turned on, the POS terminal number where the order was initiated appears on the order card.',
    posSwitchTitle: 'Show POS terminal number',
    posSwitchTurnedOff: 'Show POS terminal number is turned off',
    posSwitchTurnedOn: 'Show POS terminal number is turned on',
    previewButtonTitle: 'Preview Order Card',
    recoveryNotificationSubtitle:
      'When turned on, recover DOC messages will display on the order card.',
    recoveryNotificationTitle: 'Show Recovery Notification',
    recoveryNotificationTurnedOff: 'Show Recovery Notification is turned off',
    recoveryNotificationTurnedOn: 'Show Recovery Notification is turned on',
    reviewPagePt1: 'Enable Alert Timer Override is turned on - ',
    reviewPagePt2: ' second decrease',
    reviewUiConfigCardType: 'Card Type - ',
    reviewUiConfigType: 'Type - ',
    secondsError: '0-59',
    secondsHelperText: '0-59 seconds',
    showNoOtherItemsHeading: 'Show ”no other items” alert',
    showNoOtherItemsMessage:
      'When turned on, a ‘no other items’ text will show on the order card for this profile.',
    showNoOtherItemsOff: 'Show ”no other items” alert is turned off',
    showNoOtherItemsOn: 'Show ”no other items” alert is turned on',
    uiConfigCardType: 'Card Type',
    uiConfigCardTypePlaceholder: 'Select card type',
    uiConfigType: 'Type',
    uiConfigTypePlaceholder: 'Select type',
  },
  OrderDestinations: {
    cateringTitle: 'Catering',
    digitalOrdersTitle: 'Digital Orders',
    onSitePosTitle: 'On-Site POS',
    pageHoldText: 'Order Destinations Development In Progress!',
    pageSubtitle:
      'Choose order destinations that route to VP devices with this profile',
    pageTitle: 'Order Destinations',
    selectAllButtonTitle: 'Select all',
    thirdPartyTitle: 'Third Party',
  },
  OrderStates: {
    cancelledAndRefundSubtitle:
      'Turn on this setting to show cancelled and refunded orders for this profile',
    cancelledAndRefundTitle: 'Show cancelled and refunded orders',
    cancelledAndRefundTurnedOn:
      'Show cancelled and refunded orders is turned on',
    completedOrdersSubtitle:
      'Turn on this setting to show completed orders for this profile',
    completedOrdersTitle: 'Show completed orders',
    completedOrdersTurnedOn: 'Show completed orders is turned on',
    pageSubtitle:
      'Choose the order states that route to VP devices with this profile',
    pageTitle: 'Order Status',
    visibleOrderStatesSubtitle:
      'Choose which order states appear for this profile',
    visibleOrderStatesTitle: 'Visible order states',
  },
  PreviewButton: {
    preview: 'Preview',
  },
  PreviewCard: {
    buttonClose: 'Close',
    cardName: 'Samuel',
    cardNumber: '#1946',
    cardOrderDestination: 'DINE-IN',
    cardOrderStatus: 'PAID',
    cardPOSTerminalNumber: 'R5',
    cardTime: '00:00',
    header: 'Preview Order Card',
    meals: {
      cfaMeal: '1-CFA Meal',
      cfaSand: '1-CFA Sand',
      juice: '1-Apple Juice',
      ketchup: '2-Ketchup',
      kidsMeal: `1-Kid's Meal`,
      mFries: '1-M Waffle Fries',
      milkshake: '1-Van Milkshake',
      nuggets: '1-5ct Nuggets',
      sFries: '1-S Waffle Fries',
      toy: '1-3 & Under Toy',
    },
    subtitle:
      'The menu items shown in this preview do not reflect the actual menu items that will be displayed.',
  },
  ProfileGroup: {
    addProfile: 'Add Profile',
    emptyGroup: 'No profiles added',
  },
  ProfileNameInput: {
    cancelButton: 'Cancel',
    createConfirmLabel: 'Continue',
    createHeader: 'Create profile',
    createLabel: 'Name',
    createPlaceholder: 'Enter a name',
    createSubtitle:
      'First, choose a name for the new profile. You can change this name later.',
    editConfirmLabel: 'Continue',
    editHeader: 'Rename profile',
    editLabel: 'Name',
    editPlaceholder: '',
    editSubtitle: '',
    renameButton: 'Rename',
  },
  ProfilePopOver: {
    copyProfileLabel: 'Copy',
    editButtonLabel: 'Edit',
    moveToGroupLabel: 'Move to a Group',
    permanentlyDeleteButtonLabel: 'Permanently Delete',
    removeFromGroup: 'Remove from Group',
    renameButtonLabel: 'Rename',
  },

  ReceiptPrinting: {
    allTransactionsReceiptType: 'All transactions',
    allTransactionsReceiptValue: 'AllTransactionsReceipt',
    customerReceiptDescriptionText:
      'A TM88 printer must be installed for this receipt to print',
    customerReceiptType: 'Customer Receipt',
    customerReceiptValue: 'CustomerReceipt',
    editPageDescription:
      'Customize which receipt prints for all orders on VP devices with this profile',
    fontSizeForMenuItems: 'Font size for menu items',
    itemLabelsReceiptAllRoutedItemsTooltip:
      'When turned on, an item label will print for all items that are routed to this profile',
    itemLabelsReceiptType: 'Items label (Specials)',
    large: 'Large',
    medium: 'Medium',
    printAreaForBarcode: 'Print area for barcode',
    printCustomerReceiptDescription:
      'When turned on, a customer receipt will print when an order is bumped on VP devices within this profile',
    printCustomerReceiptTitle: 'Print customer receipt',
    printFulfillmentType: 'Print fulfillment type (e.g., dine in)',
    printGuestDescriptor: 'Print guest descriptor',
    printGuestName: 'Print guest name',
    printGuestPhoneNumber: 'Print guest phone number',
    printItemLabelForRoutedItems: 'Print item label for all routed items',
    printNoItemsAlert: 'Print "no other items" alert',
    printOnBumpDescription:
      'When turned on, this profile will print receipt(s) when you press the bump button',
    printOnBumpTitle: 'Print receipt on bump',
    printOrderNumber: 'Print order number',
    printReceiptDescription:
      'When turned on, this profile can print receipt(s) when you press the print button',
    printReceiptTitle: 'Enable receipt printing',
    printRegisterNumber: 'Print register number',
    printServiceReceiptTitle: 'Print service receipt',
    printTeamMemberName: 'Print team member name',
    printViaAnotherProfileDescription:
      'Select another profile that is connected to an in-store printer to print receipts from:',
    printViaAnotherProfileTitle: 'Print via another profile',
    printViaAnotherProfileTooltip:
      'Note: If a profile is set to print via this profile, this profile cannot print via another profile.',
    reviewDisableText: 'Receipt printing disabled',
    reviewPrintOnBumpText: 'Print receipt on bump',
    reviewPrintReceiptText: 'Enable receipt printing',
    routedItemsReceiptNoOtherItemsTooltip:
      'When turned on, a "no other items" text label will print on routed items receipts for this profile',
    routedItemsReceiptType: 'Routed items',
    routedItemsReceiptValue: 'RoutedItemsReceipt',
    selectReceiptTypeErrorText: 'Select a type of receipt',
    small: 'Small',
    specialsReceiptValue: 'SpecialsReceipt',
    typeReceiptTitle: 'Type of receipt',
    typeServiceReceiptDescription:
      'Select a type of receipt to print for all orders',
  },
  RemoveGroupProfile: {
    cancelLabel: 'Cancel',
    confirmLabel: 'Remove from group',
    dropDownTitle: 'Activity Level',
    header: 'Remove ',
    placeholder: 'Select activity level',
    subHeader: 'This profile has ',
    subheader2:
      'activity levels. Choose one activity level to be used for this profile’s configurations once it’s removed from the group:',
  },
  Review: {
    bumpsLabel: 'Bumps',
    destinationsLabel: 'Origin & Fulfillment',
    editLabel: 'Edit',
    itemRoutingLabel: 'Item Routing',
    itemRoutingLableAlwaysShow: 'Always Show',
    itemRoutingLablewhenModifiedLabel: 'When Modified',
    layoutLabel: 'Layout',
    menuItemsLabel: 'Menu Items',
    orderCardsLabel: 'Order Cards',
    orderDestinations: 'Order Destinations',
    orderStatesLabel: 'Order States',
    receiptPrintingLabel: 'Receipt Printing',
    roleLabel: 'Role',
  },
  ReviewBumpPhase2: {
    finalBump: 'Do not final bump',
    leadBumpListeners: 'Enable lead bump is turned on',
    orderStates: 'Order states',
    updateItemOrder: 'Update items on order',
  },
  RoleLabel: {
    roleHeaderBeginning: 'Choose ',
    roleHeaderBold: 'one ',
    roleHeaderEnd: 'role for your profile',
  },
  RoutedItems: {
    homePageDescription:
      'Prints only items that have been routed to the profile',
    homePageTitle: 'Routed Items Receipt',
    menuItemsPrintNo: 'Print ’no other items’ alert',
    menuItemsPrintNoDescription:
      'When turned on, a ’no other items’ text label will print on routed items receipts for this profile',
    menuItemsPrintOnly: 'Print only if specials / modified exist in profile',
  },
  ServiceReceipts: {
    allTransactionsLabel: 'Prints all items on the order',
    customerCustomerName: 'Customer name',
    customerCustomerPhoneNumber: 'Customer phone number',
    customerTitle: 'Customer',
    fulfillmentCustomerDescriptors: 'Customer Descriptors',
    fulfillmentCustomerFulfillmentMethod: 'Customer fulfillment method',
    fulfillmentDeliveryZone: 'Delivery zone',
    fulfillmentTitle: 'Fulfillment',
    homePageDescription:
      'Customize the information that displays on different service receipts',
    homePageTitle: 'Service Receipts',
    itemsLabelLabel:
      'Prints individual item receipts for items denoted as Specials',
    menuItemsFontSize: 'Font size for menu items',
    menuItemsFontSizeLargeButton: 'Large',
    menuItemsFontSizeSmallButton: 'Small',
    menuItemsTitle: 'Menu Items',
    orderTakingOrderNumber: 'Order number',
    orderTakingRegisterNumber: 'Register number',
    orderTakingTeamMemberName: 'Team member name',
    orderTakingTitle: 'Order-Taking',
    printsByDefault: 'Prints by default',
    routedItemsLabel: 'Prints only items that have been routed to the profile',
  },
  SpecialItems: {
    lastUpdatedText: '{{date}} at {{time}} by {{name}}',
    nameHeader: 'Items',
    noResultsFound: 'No results found',
    searchLabel: 'Search Items',
    specialItemsDescription: 'Modify which items are considered Special Items',
    specialItemsHeader: 'Special Items',
    specialLabel: 'Is Special',
    specialsHeader: 'Special Status',
  },
  SpeedOfServiceReports: {
    alertTimeheader: 'Alert Time',
    alertTimeSubheader: 'In minutes and seconds',
    cancelDelete: 'Cancel',
    cautionTimeheader: 'Caution Time',
    cautionTimeSubheader: 'In minutes and seconds',
    deleteButtonLabel: 'Delete',
    deleteReportHeader: 'Delete Report',
    deleteReportSubHeader: 'Are you sure want to delete the report, ',
    destinationLabel: 'Destination',
    duplicateNameError: 'This name is already being used for another Report',
    editButtonLabel: 'Edit',
    intervalTimeheader: 'Interval Time',
    intervalTimeheader2:
      'Displays average Speed of Service within chosen Interval Time',
    newReportButton: 'New Report',
    newReportModalCancel: 'Cancel',
    newReportModalLabel: 'Report Name',
    newReportModalPlaceHolder: 'Enter report name',
    newReportModalSave: 'Save',
    newReportModalTitle: 'New Report',
    selectAlertTimers: 'Select Alert Timers',
    selectDestination: 'Select Destination(s)',
    selectProfile: 'Select Profile (optional)',
    title: 'Speed of Service Reports',
    viewpointPorfileheader: 'ViewPoint Profile',
    viewPointProfileSubheader:
      'Profiles can only have one Speed of Service Report associated. This is indicated by an asterisk*. If an associated profile is selected, the old report will be overridden and replaced with the new report.',
  },

  Stepper: {
    backButton: 'Back',
    bumpsLabel: 'Bumps',
    destinationsLabel: 'Origin & Fulfillment',
    ItemRoutingLabel: 'Item Routing',
    layoutLabel: 'Layout',
    menuitemsLabel: 'Menu Items',
    nextButton: 'Next',
    orderCardDetailsLabel: 'Order Card Details',
    orderDestinationsLable: 'Order Destinations',
    orderStatesLabel: 'Order States',
    receiptPrintingLabel: 'Receipt Printing',
    reviewLabel: 'Review',
    rolelabel: 'Role',
    submitButton: 'Submit',
  },
  Venues: {
    activeVenuesLabel: 'Active Venues',
    addVenueButton: 'Add a Venue',
    archivedVenuesLabel: 'Archived Venues',
    createModalCancelButton: 'Cancel',
    createModalSubmitButton: 'Create Venue',
    detailsActivateButton: 'Activate this Venue',
    detailsArchiveButton: 'Archive this Venue',
    detailsDuplicateButton: 'Duplicate this Venue',
    detailsSaveButton: 'Save',
    emptyActiveVenuesLabel: 'No active venues',
    emptyArchivedVenuesLabel: 'No archived venues',
    idLabel: '({{storeNumber}}:{{venueId}})',
    popoverMenuActivateButton: 'Activate',
    popoverMenuArchiveButton: 'Archive',
    popoverMenuDeleteButton: 'Permanently Delete',
    popoverMenuDuplicateButton: 'Duplicate',
    popoverMenuEditButton: 'View/Edit Venue',
    popoverMenuResendButton: 'Resend Venue Data',
    promptModalCancelButton: 'Cancel',
    venueChangeButton: 'Change',
    venueChangeLabel: 'Venue: {{name}}',
    venueDetailActiveLabel: 'Status: Active',
    venueDetailArchivedLabel: 'Status: Archived',
    venueModalCancelButton: 'Cancel',
    venueModalHeader: 'Select a venue',
    venueModalSelectLabel: 'Venue',
    venueModalSubmitButton: 'Select',
  },
};
